// @ts-nocheck

import DesktopOutlined from '@ant-design/icons/DesktopOutlined';
import DashboardOutlined from '@ant-design/icons/DashboardOutlined';
import SlidersOutlined from '@ant-design/icons/SlidersOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import ShopOutlined from '@ant-design/icons/ShopOutlined';
import ControlOutlined from '@ant-design/icons/ControlOutlined';
import WalletOutlined from '@ant-design/icons/WalletOutlined';
import FilterOutlined from '@ant-design/icons/FilterOutlined';
import HddOutlined from '@ant-design/icons/HddOutlined';
import WifiOutlined from '@ant-design/icons/WifiOutlined';
import ShoppingCartOutlined from '@ant-design/icons/ShoppingCartOutlined';
import TransactionOutlined from '@ant-design/icons/TransactionOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import RedEnvelopeOutlined from '@ant-design/icons/RedEnvelopeOutlined'

export default {
  DesktopOutlined,
DashboardOutlined,
SlidersOutlined,
SettingOutlined,
ShopOutlined,
ControlOutlined,
WalletOutlined,
FilterOutlined,
HddOutlined,
WifiOutlined,
ShoppingCartOutlined,
TransactionOutlined,
UserOutlined,
RedEnvelopeOutlined
}
    