import { history } from 'umi';
import type { Effect, Reducer } from 'umi';

import UserService from '@/services/user';

export type CurrentUser = {
  avatar?: string;
  name?: string;
  title?: string;
  currentAuthority?: string;
  signature?: string;
  tags?: {
    key: string;
    label: string;
  }[];
  userid?: string;
  unreadCount?: number;
};

export type UserModelState = {
  currentUser?: CurrentUser;
};

export type UserModelType = {
  namespace: 'user';
  state: UserModelState;
  effects: {
    fetch: Effect;
    fetchCurrent: Effect;
  };
  reducers: {
    saveCurrentUser: Reducer<UserModelState>;
    changeNotifyCount: Reducer<UserModelState>;
  };
};

const UserModel: UserModelType = {
  namespace: 'user',

  state: {
    currentUser: {},
  },

  effects: {
    *fetch(_, { call, put }) {},

    *fetchCurrent(_, { call, put }) {
      try {
        const response = yield call(UserService.queryMe);

        const { result } = response.data;
        if (!result) {
          throw new Error('获取用户信息失败');
        }
        // result.shopId = result.stores[0].id;
        yield put({
          type: 'saveCurrentUser',
          payload: result,
        });
      } catch (e) {
        yield put({
          type: 'saveCurrentUser',
          payload: {},
        });
        localStorage.removeItem('token');
      }
    },
  },

  reducers: {
    saveCurrentUser(state, action) {
      return {
        ...state,
        currentUser: action.payload || {},
      };
    },
    changeNotifyCount(
      state = {
        currentUser: {},
      },
      action,
    ) {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          notifyCount: action.payload.totalCount,
          unreadCount: action.payload.unreadCount,
        },
      };
    },
  },
};

export default UserModel;
