import gql from 'graphql-tag';
import client from '@/options/apollo';

export default {
  queryMe() {
    return client.query({
      query: gql`
        query {
          result: me {
            currentAuthority: role
            avatar: avatar
            name: nickname
            unreadCount
          }
        }
      `,
      variables: {},
      fetchPolicy: 'no-cache',
    });
  },
};
