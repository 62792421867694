import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';

export const apiOrigin =
  process.env.NODE_ENV === 'development'
    ? 'https://od.kailangtu.com/api'
    : //? 'https://api.cabinet.smshj.com'
      'https://od.kailangtu.com/api';

const httpLink = createHttpLink({
  uri:
    process.env.NODE_ENV === 'development'
      ? `https://od.kailangtu.com/graphql`
      : `https://od.kailangtu.com/graphql`,
});

const authLink = setContext(async (_, { headers }) => {
  const accessToken = localStorage.getItem('token');

  // 设置token
  const authorizationHeader = accessToken ? { authorization: `Bearer ${accessToken}` } : {};

  return {
    headers: {
      ...headers,
      ...authorizationHeader,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({ addTypename: false }),
});

export const makeClient = () => client;

export default client;
