// import { stringify } from 'querystring';
import type { Reducer, Effect } from 'umi';
import { history } from 'umi';
import Service from '@/services/login';

import { setAuthority } from '@/utils/authority';
import { getPageQuery } from '@/utils/utils';

export type StateType = {
  status?: 'ok' | 'error';
  type?: string;
  currentAuthority?: 'user' | 'guest' | 'admin';
};

export type LoginModelType = {
  namespace: string;
  state: StateType;
  effects: {
    login: Effect;
    logout: Effect;
  };
  reducers: {
    changeLoginStatus: Reducer<StateType>;
  };
};

const Model: LoginModelType = {
  namespace: 'login',

  state: {
    status: undefined,
  },

  effects: {
    *login({ payload }, { call, put }) {
      const { userName: account, password, type } = payload;
      const roles = ['superadmin', 'agency', 'server', 'platformadmin'];

      try {
        const response: any = yield call(Service.login, {
          username: account,
          password,
          roles,
        });
        const { result } = response.data;
        if (result.code === 200) {
          localStorage.setItem('token', result.token);

          yield put({
            type: 'changeLoginStatus',
            payload: {
              status: 'ok',
              type,
              currentAuthority: result.me.currentAuthority,
            },
          });
          const urlParams = new URL(window.location.href);
          const params = getPageQuery();
          let { redirect } = params as { redirect: string };
          if (redirect) {
            const redirectUrlParams = new URL(redirect);
            if (redirectUrlParams.origin === urlParams.origin) {
              redirect = redirect.substr(urlParams.origin.length);
              if (redirect.match(/^\/.*#/)) {
                redirect = redirect.substr(redirect.indexOf('#') + 1);
              }
            } else {
              window.location.href = '/';
              return;
            }
          }
          history.replace(redirect || '/');
        } else {
          yield put({
            type: 'changeLoginStatus',
            payload: {
              status: 'error',
              type,
            },
          });
        }
      } catch (e) {
        // console.log('登录发生错误', e);
        yield put({
          type: 'changeLoginStatus',
          payload: {
            status: 'error',
            type: 'account',
          },
        });
      }
    },

    logout() {
      const { redirect } = getPageQuery();
      // Note: There may be security issues, please note
      localStorage.clear();
      if (window.location.pathname !== '/user/login' && !redirect) {
        history.replace({
          pathname: '/user/login',
        });
      }
    },
  },

  reducers: {
    changeLoginStatus(state, { payload }) {
      setAuthority(payload.currentAuthority);
      return {
        ...state,
        status: payload.status,
        type: payload.type,
      };
    },
  },
};

export default Model;
