import gql from 'graphql-tag';
import client from '../options/apollo';

export default {
  login(payload: { username: string; password: string; role: string[] }) {
    return client.mutate({
      mutation: gql`
        mutation($username: String, $password: String, $roles: [AdminRole]) {
          result: loginWithUsernameAndPassword(
            username: $username
            password: $password
            roles: $roles
          ) {
            token: accessToken
            code
            me {
              username
              nickname
              avatar
              phone
              currentAuthority: role
            }
          }
        }
      `,
      variables: payload,
      fetchPolicy: 'no-cache',
    });
  },
};
