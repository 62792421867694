export default {
  'menu.welcome': '欢迎',

  'menu.home': '控制台',
  'menu.home.accountInfo': '账号信息',
  'menu.home.notification': '消息通知',
  'menu.home.personalCenter': '个人中心',
  'menu.home.password': '修改密码',
  'menu.home.business': '合伙商家管理',
  'menu.home.wxset': '微信进件',
  'menu.home.wxmerchantidset': '微信分账账号',

  'menu.item': '商品查询',
  'menu.admin': '管理页',
  'menu.login': '登录',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': '表单页',
  'menu.banner': '轮播图管理',
  'menu.atlas': '设备图集',

  'menu.product': '商品库管理',
  'menu.product.itemLib': '商品管理',
  'menu.product.itemLibCategory': '商品分类管理',
  'menu.product.itemLibBrand': '商品品牌管理',
  'menu.product.itemLibApplyWithPagination': '查看申请入库',

  'menu.account': '个人页',
  'menu.account.center': '个人中心',
  'menu.account.settings': '个人设置',
  'menu.account.trigger': '触发报错',
  'menu.account.logout': '退出登录',

  'menu.superAdminControl': '服务商管理',
  'menu.superAdminControls': '运营商查询',
  'menu.superAdministrator': '设备管理',

  'menu.serverControl': '运营商管理',
  'menu.serverControl.control': '运营商管理',
  'menu.serverControl.Daily': '运营商日报',
  'menu.serverControl.aliFranchisee': '直付通进件',

  'menu.simControl': '物联卡管理',
  'menu.simControl.simFlowLimit': '流量套餐管理',

  'menu.OrderDetail': '订单详情',
  'menu.theme': '主题设置',
  'menu.orderDetail': '订单详情',
  'menu.adminManualConfig': 'AI开放平台配置',
  'menu.itemhouse': '商品库管理',

  // 'menu.capitalCategory': '资金类目',
  // 'menu.capitalCategory.capitalSettlement': '资金类目',
  // 'menu.capitalCategory.capitalSettlementCheck': '资金结算',
  // 'menu.capitalCategory.capitalDetails': '资金明细',
  // 'menu.capitalCategory.withdrawal': '资金提现',
  // 'menu.capitalCategory.withdrawalCheck': '审核点位提现',
  // 'menu.capitalCategory.withdrawalApply': '申请提现',
  // 'menu.capitalCategory.withdrawalsuperadmincheck': '审核提现',
  // 'menu.capitalCategory.withdrawalInformation': '提现资料',
  // 'menu.capitalCategory.withdrawalInformationByServer': '运营商提现资料',

  'menu.distribution': '配送类目',
  'menu.distribution.deliveryman': '人员管理',
  'menu.distribution.authorizedEquipment': '授权设备',
  'menu.distribution.supplementRecordWithPagination': '库存变动记录',
  'menu.distribution.distributionList': '配送单管理',

  'menu.equipmentManagement': '设备类目',
  'menu.equipmentManagement.deviceList': '我的设备',
  'menu.equipmentManagement.deviceByServer': '下属设备',
  'menu.equipmentManagement.deviceByView': '我的设备',
  'menu.equipmentManagement.atlas': '识别记录',
  'menu.equipmentManagement.details': '设备详情',
  'menu.equipmentManagement.adBank': '广告素材库',
  'menu.equipmentManagement.deviceType': '设备类别',
  'menu.equipmentManagement.deviceTypeTemplate': '设备类型模板',
  'menu.equipmentManagement.advertisingManagement': '广告管理',
  'menu.equipmentManagement.deviceModel': '设备模型',
  'menu.equipmentManagement.outstock': '缺货清单',

  'menu.commodityManagement': '商品类目',
  'menu.commodityManagement.commodityList': '商品列表',
  'menu.commodityManagement.commodityAdd': '添加商品',
  'menu.commodityManagement.commodityPrice': '商品价格管理',
  'menu.commodityManagement.itemLib': '商品库',
  'menu.commodityManagement.itemLibApply': '入库申请',
  'menu.commodityManagement.priceRule': '价格策略管理',

  'menu.transactionManagement': '交易类目',
  'menu.transactionManagement.order': '订单管理',
  'menu.transactionManagement.orderview': '订单管理',
  'menu.transactionManagement.abnormalOrder': '异常订单',
  'menu.transactionManagement.superadminOrder': '订单管理',
  'menu.transactionManagement.superadminAbnormalOrder': '异常订单审核',
  'menu.transactionManagement.transactionBill': '交易账单',
  'menu.transactionManagement.orderDetail': '订单详情',
  'menu.transactionManagement.refundOrder': '退款管理',
  'menu.transactionManagement.refundOrderDetail': '退款订单详情',
  'menu.transactionManagement.replenishorder': '补单记录',

  'menu.memberCategory': '会员类目',
  'menu.memberCategory.memberManage': '会员管理',
  'menu.memberCategory.blacklist': '用户黑名单',
  'menu.memberCategory.memberManageProvider': '会员管理',

  'menu.rules': '策略规则类目',
  'menu.rules.priceRule': '价格策略管理',
  'menu.rules.distributionRules': '配送规则管理',
  'menu.rules.priceDetail': '价格详情',

  'menu.activity': '营销管理',
  'menu.activity.secondHalfDiscount': '优惠活动',

  'menu.workOrder': '工单管理',
  'menu.workOrder.modelWorkOrder': '模型工单',
  'menu.workOrder.modelWorkOrderPlatform': '模型工单',

  'menu.charge': '充值管理',
  'menu.charge.deviceRechargePackage': '套餐管理',
  'menu.charge.deviceRechargeOrder': '充值订单',
  'menu.charge.identityLog': '识别记录',
  'menu.charge.identity': '订单算法服务费',
  'menu.charge.devices': '设备续费',
};
