// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/home/ksepton/workspace/server-console-master/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/user",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__UserLayout' */'/home/ksepton/workspace/server-console-master/src/layouts/UserLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "name": "login",
        "path": "/user/login",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__login' */'/home/ksepton/workspace/server-console-master/src/pages/user/login'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__SecurityLayout' */'/home/ksepton/workspace/server-console-master/src/layouts/SecurityLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'/home/ksepton/workspace/server-console-master/src/layouts/BasicLayout'), loading: LoadingComponent}),
        "authority": [
          "superadmin",
          "server",
          "agency",
          "platformadmin",
          "business",
          "deviceByServer"
        ],
        "routes": [
          {
            "path": "/",
            "redirect": "/home/account_info",
            "exact": true
          },
          {
            "name": "home",
            "icon": "desktop",
            "authority": [
              "agency",
              "server",
              "superadmin",
              "platformadmin",
              "business",
              "deviceByServer"
            ],
            "path": "/home",
            "routes": [
              {
                "name": "accountInfo",
                "path": "/home/account_info",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Home__AccountInfo' */'/home/ksepton/workspace/server-console-master/src/pages/Home/AccountInfo'), loading: LoadingComponent}),
                "authority": [
                  "agency",
                  "server",
                  "superadmin",
                  "platformadmin",
                  "business",
                  "deviceByServer"
                ],
                "exact": true
              },
              {
                "name": "notification",
                "path": "/home/notification",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Home__Notification' */'/home/ksepton/workspace/server-console-master/src/pages/Home/Notification'), loading: LoadingComponent}),
                "authority": [
                  "agency",
                  "server",
                  "superadmin",
                  "platformadmin",
                  "business",
                  "deviceByServer"
                ],
                "exact": true
              },
              {
                "name": "personalCenter",
                "path": "/home/personal_center",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Home__PersonalCenter' */'/home/ksepton/workspace/server-console-master/src/pages/Home/PersonalCenter'), loading: LoadingComponent}),
                "authority": [
                  "superadmin"
                ],
                "exact": true
              },
              {
                "name": "password",
                "path": "/home/password",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Home__Password' */'/home/ksepton/workspace/server-console-master/src/pages/Home/Password'), loading: LoadingComponent}),
                "authority": [
                  "agency",
                  "server",
                  "superadmin",
                  "platformadmin",
                  "business",
                  "deviceByServer"
                ],
                "exact": true
              },
              {
                "name": "wxset",
                "path": "/home/Wxset",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Home__Wxset' */'/home/ksepton/workspace/server-console-master/src/pages/Home/Wxset'), loading: LoadingComponent}),
                "authority": [
                  "agency",
                  "server",
                  "superadmin",
                  "platformadmin",
                  "business",
                  "deviceByServer"
                ],
                "exact": true
              },
              {
                "name": "wxmerchantidset",
                "path": "/home/WxMerchantidSet",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Home__WxMerchantidSet' */'/home/ksepton/workspace/server-console-master/src/pages/Home/WxMerchantidSet'), loading: LoadingComponent}),
                "authority": [
                  "agency",
                  "server",
                  "superadmin",
                  "platformadmin",
                  "business",
                  "deviceByServer"
                ],
                "exact": true
              }
            ]
          },
          {
            "name": "product",
            "icon": "dashboard",
            "path": "/product",
            "authority": [
              "platformadmin"
            ],
            "routes": [
              {
                "name": "itemLib",
                "path": "/product/item_lib",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__ItemLib' */'/home/ksepton/workspace/server-console-master/src/pages/Product/ItemLib'), loading: LoadingComponent}),
                "authority": [
                  "platformadmin"
                ],
                "exact": true
              },
              {
                "name": "itemLibCategory",
                "path": "/product/item_lib_category",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__ItemLibCategory' */'/home/ksepton/workspace/server-console-master/src/pages/Product/ItemLibCategory'), loading: LoadingComponent}),
                "authority": [
                  "platformadmin"
                ],
                "exact": true
              },
              {
                "name": "itemLibBrand",
                "path": "/product/item_lib_brand",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__ItemLibBrand' */'/home/ksepton/workspace/server-console-master/src/pages/Product/ItemLibBrand'), loading: LoadingComponent}),
                "authority": [
                  "platformadmin"
                ],
                "exact": true
              },
              {
                "name": "itemLibApplyWithPagination",
                "path": "/product/item_lib_apply",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__ItemLibApplyWithPagination' */'/home/ksepton/workspace/server-console-master/src/pages/Product/ItemLibApplyWithPagination'), loading: LoadingComponent}),
                "authority": [
                  "platformadmin"
                ],
                "exact": true
              }
            ]
          },
          {
            "name": "equipmentManagement",
            "icon": "sliders",
            "authority": [
              "agency",
              "platformadmin",
              "server",
              "business",
              "deviceByServer"
            ],
            "path": "/equipment_management",
            "routes": [
              {
                "name": "deviceList",
                "path": "/equipment_management/devices",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EquipmentManagement__Devices' */'/home/ksepton/workspace/server-console-master/src/pages/EquipmentManagement/Devices'), loading: LoadingComponent}),
                "authority": [
                  "agency"
                ],
                "exact": true
              },
              {
                "name": "deviceByServer",
                "path": "/equipment_management/devices_server",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EquipmentManagement__DevicesByServer' */'/home/ksepton/workspace/server-console-master/src/pages/EquipmentManagement/DevicesByServer'), loading: LoadingComponent}),
                "authority": [
                  "server"
                ],
                "exact": true
              },
              {
                "name": "deviceByView",
                "path": "/equipment_management/devices_view",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EquipmentManagement__DevicesByView' */'/home/ksepton/workspace/server-console-master/src/pages/EquipmentManagement/DevicesByView'), loading: LoadingComponent}),
                "authority": [
                  "business",
                  "deliveryman"
                ],
                "exact": true
              },
              {
                "name": "details",
                "hideInMenu": true,
                "path": "/equipment_management/devices/details",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EquipmentManagement__Details' */'/home/ksepton/workspace/server-console-master/src/pages/EquipmentManagement/Details'), loading: LoadingComponent}),
                "authority": [
                  "agency",
                  "server"
                ],
                "exact": true
              },
              {
                "name": "detailsView",
                "hideInMenu": true,
                "path": "/equipment_management/devices/detailsview",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EquipmentManagement__DetailViews' */'/home/ksepton/workspace/server-console-master/src/pages/EquipmentManagement/DetailViews'), loading: LoadingComponent}),
                "authority": [
                  "business",
                  "deliveryman"
                ],
                "exact": true
              },
              {
                "name": "atlas",
                "path": "/equipment_management/atlas",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EquipmentManagement__Atlas' */'/home/ksepton/workspace/server-console-master/src/pages/EquipmentManagement/Atlas'), loading: LoadingComponent}),
                "authority": [
                  "agency"
                ],
                "exact": true
              },
              {
                "name": "adBank",
                "path": "/equipment_management/ad_bank",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EquipmentManagement__AdBank' */'/home/ksepton/workspace/server-console-master/src/pages/EquipmentManagement/AdBank'), loading: LoadingComponent}),
                "authority": [
                  "server"
                ],
                "exact": true
              },
              {
                "name": "advertisingManagement",
                "path": "/equipment_management/advertising_management",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EquipmentManagement__AdvertisingManagement' */'/home/ksepton/workspace/server-console-master/src/pages/EquipmentManagement/AdvertisingManagement'), loading: LoadingComponent}),
                "authority": [
                  "server"
                ],
                "exact": true
              },
              {
                "name": "deviceType",
                "path": "/equipment_management/device_type",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EquipmentManagement__DeviceType' */'/home/ksepton/workspace/server-console-master/src/pages/EquipmentManagement/DeviceType'), loading: LoadingComponent}),
                "authority": [
                  "platformadmin"
                ],
                "exact": true
              },
              {
                "name": "deviceModel",
                "path": "/equipment_management/device_model",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EquipmentManagement__DeviceModel' */'/home/ksepton/workspace/server-console-master/src/pages/EquipmentManagement/DeviceModel'), loading: LoadingComponent}),
                "authority": [
                  "platformadmin"
                ],
                "exact": true
              },
              {
                "name": "deviceTypeTemplate",
                "path": "/equipment_management/device_typetemplate",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EquipmentManagement__DeviceTypeTemplate' */'/home/ksepton/workspace/server-console-master/src/pages/EquipmentManagement/DeviceTypeTemplate'), loading: LoadingComponent}),
                "authority": [
                  "platformadmin"
                ],
                "exact": true
              },
              {
                "name": "outstock",
                "path": "/equipment_management/outstock",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EquipmentManagement__Outstock' */'/home/ksepton/workspace/server-console-master/src/pages/EquipmentManagement/Outstock'), loading: LoadingComponent}),
                "authority": [
                  "agency"
                ],
                "exact": true
              }
            ]
          },
          {
            "name": "adminManualConfig",
            "icon": "setting",
            "path": "/admin_manual_config",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AdminManualConfig' */'/home/ksepton/workspace/server-console-master/src/pages/AdminManualConfig'), loading: LoadingComponent}),
            "authority": [
              "platformadmin"
            ],
            "exact": true
          },
          {
            "name": "commodityManagement",
            "icon": "shop",
            "path": "/commodity_management",
            "authority": [
              "agency"
            ],
            "routes": [
              {
                "name": "commodityList",
                "path": "/commodity_management/commodity_list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Goods__GoodsList' */'/home/ksepton/workspace/server-console-master/src/pages/Goods/GoodsList'), loading: LoadingComponent}),
                "authority": [
                  "agency"
                ],
                "exact": true
              },
              {
                "name": "commodityAdd",
                "path": "/commodity_management/commodity_list/add",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Goods__Add' */'/home/ksepton/workspace/server-console-master/src/pages/Goods/Add'), loading: LoadingComponent}),
                "authority": [
                  "agency"
                ],
                "hideInMenu": true,
                "exact": true
              },
              {
                "name": "commodityPrice",
                "path": "/commodity_management/commodity_price",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Goods__GoodsPrice' */'/home/ksepton/workspace/server-console-master/src/pages/Goods/GoodsPrice'), loading: LoadingComponent}),
                "authority": [
                  "agency"
                ],
                "exact": true
              },
              {
                "name": "itemLib",
                "path": "/commodity_management/item_lib",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Goods__ItemLib' */'/home/ksepton/workspace/server-console-master/src/pages/Goods/ItemLib'), loading: LoadingComponent}),
                "authority": [
                  "agency"
                ],
                "exact": true
              },
              {
                "name": "itemLibApply",
                "path": "/commodity_management/item_lib_apply",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Goods__ItemLibApplyWithPagination' */'/home/ksepton/workspace/server-console-master/src/pages/Goods/ItemLibApplyWithPagination'), loading: LoadingComponent}),
                "authority": [
                  "agency"
                ],
                "exact": true
              }
            ]
          },
          {
            "name": "superAdminControl",
            "icon": "setting",
            "path": "/super_admin_control",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SuperAdminControl__Control' */'/home/ksepton/workspace/server-console-master/src/pages/SuperAdminControl/Control'), loading: LoadingComponent}),
            "authority": [
              "superadmin"
            ],
            "exact": true
          },
          {
            "name": "superAdminControls",
            "path": "/super_admin_control/controls",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SuperAdminControl__Controls' */'/home/ksepton/workspace/server-console-master/src/pages/SuperAdminControl/Controls'), loading: LoadingComponent}),
            "hideInMenu": true,
            "authority": [
              "superadmin"
            ],
            "exact": true
          },
          {
            "name": "superAdministrator",
            "icon": "control",
            "path": "/super_administrator",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SuperAdministrator' */'/home/ksepton/workspace/server-console-master/src/pages/SuperAdministrator'), loading: LoadingComponent}),
            "authority": [
              "superadmin"
            ],
            "exact": true
          },
          {
            "name": "transactionManagement",
            "icon": "wallet",
            "path": "/transaction_management",
            "authority": [
              "agency",
              "superadmin",
              "server",
              "business"
            ],
            "routes": [
              {
                "name": "order",
                "path": "/transaction_management/order",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Order' */'/home/ksepton/workspace/server-console-master/src/pages/Order'), loading: LoadingComponent}),
                "authority": [
                  "agency"
                ],
                "exact": true
              },
              {
                "name": "orderview",
                "path": "/transaction_management/orderview",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OrderView' */'/home/ksepton/workspace/server-console-master/src/pages/OrderView'), loading: LoadingComponent}),
                "authority": [
                  "business",
                  "deliveryman"
                ],
                "exact": true
              },
              {
                "name": "orderDetail",
                "path": "/transaction_management/order/detail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Order__Detail' */'/home/ksepton/workspace/server-console-master/src/pages/Order/Detail'), loading: LoadingComponent}),
                "authority": [
                  "agency",
                  "business"
                ],
                "hideInMenu": true,
                "exact": true
              },
              {
                "name": "abnormalOrder",
                "path": "/transaction_management/abnormal_order",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Order__AbnormalOrder' */'/home/ksepton/workspace/server-console-master/src/pages/Order/AbnormalOrder'), loading: LoadingComponent}),
                "authority": [
                  "agency"
                ],
                "exact": true
              },
              {
                "name": "refundOrder",
                "path": "/transaction_management/refund_order",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Order__RefundOrder' */'/home/ksepton/workspace/server-console-master/src/pages/Order/RefundOrder'), loading: LoadingComponent}),
                "authority": [
                  "agency"
                ],
                "exact": true
              },
              {
                "name": "refundOrderDetail",
                "path": "/transaction_management/refund_order/detail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Order__RefundOrder__Detail' */'/home/ksepton/workspace/server-console-master/src/pages/Order/RefundOrder/Detail'), loading: LoadingComponent}),
                "authority": [
                  "agency"
                ],
                "hideInMenu": true,
                "exact": true
              },
              {
                "name": "transactionBill",
                "path": "/transaction_management/transaction_bill",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Order__TransactionBill' */'/home/ksepton/workspace/server-console-master/src/pages/Order/TransactionBill'), loading: LoadingComponent}),
                "authority": [
                  "agency",
                  "business"
                ],
                "exact": true
              },
              {
                "name": "superadminOrder",
                "path": "/transaction_management/superadmin_order",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Order__superadminOrder' */'/home/ksepton/workspace/server-console-master/src/pages/Order/superadminOrder'), loading: LoadingComponent}),
                "authority": [
                  "superadmin",
                  "server"
                ],
                "exact": true
              },
              {
                "name": "superadminAbnormalOrder",
                "path": "/transaction_management/superadmin_abnormal_order",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Order__superadminOrder__AbnormalOrder' */'/home/ksepton/workspace/server-console-master/src/pages/Order/superadminOrder/AbnormalOrder'), loading: LoadingComponent}),
                "authority": [
                  "superadmin",
                  "server"
                ],
                "exact": true
              },
              {
                "name": "orderDetail",
                "path": "/transaction_management/superadmin_order/detail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Order__Detail' */'/home/ksepton/workspace/server-console-master/src/pages/Order/Detail'), loading: LoadingComponent}),
                "authority": [
                  "superadmin",
                  "server"
                ],
                "hideInMenu": true,
                "exact": true
              },
              {
                "name": "replenishorder",
                "path": "/transaction_management/replenishorder",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Order__ReplenishOrder' */'/home/ksepton/workspace/server-console-master/src/pages/Order/ReplenishOrder'), loading: LoadingComponent}),
                "authority": [
                  "agency",
                  "server",
                  "superadmin"
                ],
                "exact": true
              }
            ]
          },
          {
            "name": "rules",
            "icon": "filter",
            "path": "/rules",
            "authority": [
              "agency"
            ],
            "routes": [
              {
                "name": "priceRule",
                "path": "/rules/price_rule",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Rules__PriceRule' */'/home/ksepton/workspace/server-console-master/src/pages/Rules/PriceRule'), loading: LoadingComponent}),
                "authority": [
                  "agency"
                ],
                "exact": true
              },
              {
                "name": "priceDetail",
                "hideInMenu": true,
                "path": "/rules/price_rule/detail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Rules__PriceDetail' */'/home/ksepton/workspace/server-console-master/src/pages/Rules/PriceDetail'), loading: LoadingComponent}),
                "authority": [
                  "agency"
                ],
                "exact": true
              },
              {
                "name": "distributionRules",
                "path": "/rules/distribution_rules",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Rules__Distributionrules' */'/home/ksepton/workspace/server-console-master/src/pages/Rules/Distributionrules'), loading: LoadingComponent}),
                "authority": [
                  "agency"
                ],
                "exact": true
              }
            ]
          },
          {
            "name": "theme",
            "icon": "setting",
            "path": "/theme",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Theme' */'/home/ksepton/workspace/server-console-master/src/pages/Theme'), loading: LoadingComponent}),
            "authority": [
              "server"
            ],
            "exact": true
          },
          {
            "name": "serverControl",
            "icon": "hdd",
            "path": "/server_control",
            "authority": [
              "server"
            ],
            "routes": [
              {
                "name": "control",
                "path": "/server_control/controls",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ServerControl__Controls' */'/home/ksepton/workspace/server-console-master/src/pages/ServerControl/Controls'), loading: LoadingComponent}),
                "authority": [
                  "server"
                ],
                "exact": true
              }
            ]
          },
          {
            "name": "simControl",
            "icon": "wifi",
            "path": "/sim_control",
            "authority": [
              "server"
            ],
            "routes": [
              {
                "name": "simFlowLimit",
                "path": "/sim_control/sim_flow_limit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__simControl__SimFlowLimit' */'/home/ksepton/workspace/server-console-master/src/pages/simControl/SimFlowLimit'), loading: LoadingComponent}),
                "authority": [
                  "server"
                ],
                "exact": true
              }
            ]
          },
          {
            "name": "distribution",
            "icon": "shopping-cart",
            "path": "/distribution",
            "authority": [
              "agency"
            ],
            "routes": [
              {
                "name": "supplementRecordWithPagination",
                "path": "/distribution/supplement_record_with_pagination",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Distribution__SupplementRecordWithPagination' */'/home/ksepton/workspace/server-console-master/src/pages/Distribution/SupplementRecordWithPagination'), loading: LoadingComponent}),
                "authority": [
                  "agency"
                ],
                "exact": true
              },
              {
                "name": "authorizedEquipment",
                "path": "/distribution/authorizedequipment",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Distribution__Authorizedequipment' */'/home/ksepton/workspace/server-console-master/src/pages/Distribution/Authorizedequipment'), loading: LoadingComponent}),
                "authority": [
                  "agency"
                ],
                "exact": true
              },
              {
                "name": "deliveryman",
                "path": "/distribution/deliveryman",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Distribution__Deliveryman' */'/home/ksepton/workspace/server-console-master/src/pages/Distribution/Deliveryman'), loading: LoadingComponent}),
                "authority": [
                  "agency"
                ],
                "exact": true
              }
            ]
          },
          {
            "name": "charge",
            "icon": "transaction",
            "path": "/charge",
            "authority": [
              "agency",
              "superadmin",
              "platformadmin"
            ],
            "routes": [
              {
                "name": "deviceRechargeOrder",
                "path": "/charge/device_recharge_order",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Charge__DeviceRechargeOrder' */'/home/ksepton/workspace/server-console-master/src/pages/Charge/DeviceRechargeOrder'), loading: LoadingComponent}),
                "authority": [
                  "agency",
                  "superadmin"
                ],
                "exact": true
              },
              {
                "name": "devices",
                "path": "/charge/devices",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Charge__Devices' */'/home/ksepton/workspace/server-console-master/src/pages/Charge/Devices'), loading: LoadingComponent}),
                "authority": [
                  "agency"
                ],
                "exact": true
              },
              {
                "name": "deviceRechargePackage",
                "path": "/charge/device_recharge_package",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Charge__DeviceRechargePackage' */'/home/ksepton/workspace/server-console-master/src/pages/Charge/DeviceRechargePackage'), loading: LoadingComponent}),
                "authority": [
                  "superadmin"
                ],
                "exact": true
              },
              {
                "name": "identityLog",
                "path": "/charge/identity_log",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Charge__IdentityLog' */'/home/ksepton/workspace/server-console-master/src/pages/Charge/IdentityLog'), loading: LoadingComponent}),
                "authority": [
                  "platformadmin"
                ],
                "exact": true
              }
            ]
          },
          {
            "name": "memberCategory",
            "icon": "user",
            "path": "/member_category",
            "authority": [
              "agency",
              "superadmin"
            ],
            "routes": [
              {
                "name": "memberManage",
                "path": "/member_category/membermanage",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MemberCategory__Membermanage' */'/home/ksepton/workspace/server-console-master/src/pages/MemberCategory/Membermanage'), loading: LoadingComponent}),
                "authority": [
                  "agency"
                ],
                "exact": true
              },
              {
                "name": "blacklist",
                "path": "/member_category/balcklist",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MemberCategory__Blacklist' */'/home/ksepton/workspace/server-console-master/src/pages/MemberCategory/Blacklist'), loading: LoadingComponent}),
                "authority": [
                  "agency"
                ],
                "exact": true
              },
              {
                "name": "memberManageProvider",
                "path": "/member_category/membermanage_provider",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MemberCategory__MembermanageProvider' */'/home/ksepton/workspace/server-console-master/src/pages/MemberCategory/MembermanageProvider'), loading: LoadingComponent}),
                "authority": [
                  "superadmin"
                ],
                "exact": true
              }
            ]
          },
          {
            "name": "activity",
            "icon": "red-envelope",
            "path": "/activity",
            "authority": [],
            "routes": [
              {
                "name": "secondHalfDiscount",
                "path": "/activity/second_half_discount",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Activity__SecondHalfDiscount' */'/home/ksepton/workspace/server-console-master/src/pages/Activity/SecondHalfDiscount'), loading: LoadingComponent}),
                "authority": [],
                "exact": true
              }
            ]
          },
          {
            "name": "workOrder",
            "icon": "shop",
            "path": "/work_order",
            "authority": [
              "platformadmin"
            ],
            "routes": [
              {
                "name": "modelWorkOrder",
                "path": "/work_order/model_work_order",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkOrder__ModelWorkOrder' */'/home/ksepton/workspace/server-console-master/src/pages/WorkOrder/ModelWorkOrder'), loading: LoadingComponent}),
                "authority": [
                  "agency"
                ],
                "hideInMenu": true,
                "exact": true
              },
              {
                "name": "modelWorkOrderPlatform",
                "path": "/work_order/model_work_order_platform",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkOrder__ModelWorkOrderByPlatform' */'/home/ksepton/workspace/server-console-master/src/pages/WorkOrder/ModelWorkOrderByPlatform'), loading: LoadingComponent}),
                "authority": [
                  "platformadmin"
                ],
                "exact": true
              }
            ]
          }
        ]
      }
    ]
  },
  {
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/home/ksepton/workspace/server-console-master/src/pages/404'), loading: LoadingComponent}),
    "exact": true
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
